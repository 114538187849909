import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Box, Container, TextField, Typography, Button, Grid, Link, useTheme } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TikTokIcon from '@mui/icons-material/MusicNote';

const ContactSection = () => {
    const theme = useTheme();

    const [formData, setFormData] = useState({
        nombre: '',
        mail: '',
        telefono: '',
        asunto: '',
        mensaje: '',
    });

    const inputStyle = {
        input: {
            color: 'white',
            fontWeight: 'normal',
        },
        '& label': { color: 'white' },
        '& label.Mui-focused': { color: 'white' },
        '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'white' },
            '&:hover fieldset': { borderColor: 'white' },
            '&.Mui-focused fieldset': { borderColor: 'white' },
        },
        '& .MuiInputBase-inputMultiline': {
            color: 'white',
            fontWeight: 'normal',
        },
    };

    const iconStyle = {
        fontSize: '2rem',
        mx: 2,
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            formData,
            process.env.REACT_APP_EMAILJS_USER_ID
        ).then(() => {
            alert('Mensaje enviado con éxito!');
        }, (error) => {
            alert('Hubo un error al enviar el mensaje.');
        });

        setFormData({
            nombre: '',
            mail: '',
            telefono: '',
            asunto: '',
            mensaje: '',
        });
    };

    return (
        <Box sx={{ width: '100%', bgcolor: 'black', color: 'white', paddingTop: "1rem" }} id="contacto">
            <Container maxWidth="md" sx={{ mt: 4, mb: 2 }}>
                <Typography variant="h1" component="h1" gutterBottom  sx={{
                    textAlign: 'center',
                    fontSize: '2.5rem',
                    [theme.breakpoints.up('sm')]: {
                      fontSize: '3rem',
                    },
                    [theme.breakpoints.up('md')]: {
                      fontSize: '4rem',
                    },
                    fontWeight: "bolder"
                  }}>
                  ¡Contáctanos!
                </Typography>
                <Typography variant="h2" gutterBottom sx={{ textAlign: 'center', mb: 4, fontSize: '1.5rem', [theme.breakpoints.up('sm')]: { fontSize: '2rem' } }}>
                    Déjanos tu consulta y te responderemos a la brevedad.
                </Typography>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                variant="outlined"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleChange}
                                sx={inputStyle}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Mail"
                                variant="outlined"
                                name="mail"
                                value={formData.mail}
                                onChange={handleChange}
                                sx={inputStyle}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Teléfono"
                                variant="outlined"
                                name="telefono"
                                value={formData.telefono}
                                onChange={handleChange}
                                sx={inputStyle}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Asunto"
                                variant="outlined"
                                name="asunto"
                                value={formData.asunto}
                                onChange={handleChange}
                                sx={inputStyle}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Mensaje"
                                variant="outlined"
                                multiline
                                rows={4}
                                name="mensaje"
                                value={formData.mensaje}
                                onChange={handleChange}
                                sx={inputStyle}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button type="submit" variant="contained" endIcon={<SendIcon />} sx={{ mr: 2 }}>
                                Enviar
                            </Button>
                            <Button variant="contained" href="https://wa.link/g17zj7" endIcon={<SendIcon />} target="_blank" sx={{ backgroundColor: '#25D366', '&:hover': { backgroundColor: '#1ebe5e' } }}>
                                WhatsApp
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Link href="https://www.instagram.com/conceptpro__/" target="_blank" sx={{ mx: 1, color: 'white' }} aria-label="Instagram">
                        <InstagramIcon sx={iconStyle} />
                    </Link>
                    <Link href="https://www.youtube.com/@ConceptPro__" target="_blank" sx={{ mx: 1, color: 'white' }} aria-label="YouTube">
                        <YouTubeIcon sx={iconStyle} />
                    </Link>
                    <Link href="https://www.tiktok.com/@concept.pro" target="_blank" sx={{ mx: 1, color: 'white' }} aria-label="TikTok">
                        <TikTokIcon sx={iconStyle} />
                    </Link>
                </Box>
            </Container>
        </Box>
    );
};

export default ContactSection;
