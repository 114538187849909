import React from 'react';
import {
  RazonesWrapper,
  Title,
  List,
  ListItem,
  CheckIcon
} from './styled/RazonesStyles';

const Razones = () => {
  const razones = [
    "Calidad",
    "Prestigio",
    "Innovación",
    "Originalidad",
    "Asesoramiento",
    "Profesionalismo",
  ];

  return (
    <RazonesWrapper>
      <Title>¿Por qué elegirnos?</Title>
      <List>
        {razones.map((razon, index) => (
          <ListItem key={index}>
            <CheckIcon />
            {razon}
          </ListItem>
        ))}
      </List>
    </RazonesWrapper>
  );
};

export default Razones;
