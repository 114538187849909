import { Typography, IconButton } from "@mui/material";
import "../styles/Menu.css";
import * as React from "react";
import iconData from "./Icons";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useHamburger } from "../Contexts/BurgerContext";

const Menu = () => {
  const { hamburgerMenuOn, toggleBurgerMenu } = useHamburger();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    toggleBurgerMenu();
  };

  return (
    <>
      <div className={hamburgerMenuOn === null ? "hiddenMenu" : (hamburgerMenuOn ? "burgerMenu" : "closeBurgerMenu")}>
        <div className="itemsMenuWrap">
          <div className="itemsMenu">
            <RouterLink to="/#nosotros" onClick={() => handleNavigation('/#nosotros')}>
              <h2>Nosotros</h2>
            </RouterLink>
            <RouterLink to="/#servicios" onClick={() => handleNavigation('/#servicios')}>
              <h2>Servicios</h2>
            </RouterLink>
            <RouterLink to="/#galeria" onClick={() => handleNavigation('/#galeria')}>
              <h2>Galería</h2>
            </RouterLink>
            <RouterLink to="/#contacto" onClick={() => handleNavigation('/#contacto')}>
              <h2>Contacto</h2>
            </RouterLink>
          </div>
          <div className="redes">
            <Typography variant="h2" component="p" fontSize="1.4rem" className="textoSeguinos">
              Seguinos
            </Typography>
            <div className="iconos">
              {iconData.map((icon) => (
                <a
                  key={icon.id}
                  href={icon.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="igIconLink"
                >
                  <IconButton className="igIcon">
                    {icon.icon}
                  </IconButton>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
