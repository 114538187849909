import React, { useState, useEffect, useCallback } from "react";
import VideoLanding from "../assets/videos/videoLandingConceptDesktop.webm";
import VideoLandingcelu from "../assets/videos/videoLandingMobile.webm";
import VideoLandingFrame from "../assets/videoLandingFrame.webp";
import styles from './styled/LandingPage.module.css';

const Landing = () => {
  const [videoSrc, setVideoSrc] = useState(() =>
    window.innerWidth <= 768 ? VideoLandingcelu : VideoLanding
  );
  const [isLoading, setIsLoading] = useState(true);
  const [showImage, setShowImage] = useState(false);

  const handleResize = useCallback(() => {
    const newVideoSrc = window.innerWidth <= 768 ? VideoLandingcelu : VideoLanding;
    setVideoSrc(prevVideoSrc => {
      return prevVideoSrc !== newVideoSrc ? newVideoSrc : prevVideoSrc;
    });
  }, []);

  const handleLoadedData = () => {
    setIsLoading(false);
    setShowImage(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading && window.innerWidth <= 768) {
        setShowImage(true);
      }
    }, 2000);

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, isLoading]);

  return (
    <div className={styles.landingPage}>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <div className={styles.loader} />
        </div>
      )}
      {showImage && window.innerWidth <= 768 && (
        <img
          src={VideoLandingFrame}
          alt="Video frame"
          className={styles.videoFrame}
        />
      )}
      <video
        className={styles.videoBg}
        src={videoSrc}
        autoPlay
        muted
        loop
        playsInline
        alt="Video background"
        onLoadedData={handleLoadedData}
      />
      <div className={styles.bgOverlay} />
      <div className={styles.landingLayout}>
        <div className={styles.landingText}>
          <p className={styles.overlayText}>Productora Artistica</p>
          <h1 className={styles.title}>Concept</h1>
          <p className={styles.mainText}>Creamos experiencias que dejan huella.</p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
