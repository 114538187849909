import React from 'react';
import "../styles/Servicios.css";
import { Typography } from '@mui/material';

const Servicios = () => {
  const servicios = [
    "Recepciones",
    "Entradas especiales",
    "Apertura de tanda",
    "Vals moderno",
    "Bailarines/acróbatas",
    "Coreografías"
  ];

  return (
    <div className="servicios-container" id='servicios'>
        <Typography className="titulo-servicios" variant="h1" fontWeight={"bolder"} gutterBottom style={{ fontSize: '3.1rem' }} component={"h2"}>
        Servicios
      </Typography>
      <div className="listado-servicios">
        {servicios.map((servicio, index) => (
          <div key={index} className="item-servicio">
            <h2>{servicio}</h2>
            <div className="linea-decorativa"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Servicios;