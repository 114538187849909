import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5;
  transition: top 0.3s, background-color 0.3s;
  background: linear-gradient(to bottom, rgba(18, 16, 16, 0.55), rgba(18, 16, 16, 0.014));
  
  &.headerVisible {
    top: 0;
  }

  &.headerHidden {
    top: -4rem;
    background: transparent;
  }
`;

export const HeaderLayout = styled.div`
  height: 100%;
  width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuButton = styled.div`
  color: white;
  z-index: 3;
`;

export const Logo = styled.img`
  height: 7rem;
  width: 6rem;
`;

export const CloseLogo = styled.div`
  color: white;
  z-index: 3;
  height: 3rem;
  width: 6rem;
`;
