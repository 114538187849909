import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';
import ReactPlayer from 'react-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import '../styles/Galeria.css';
import fotoGaleria1 from "../assets/fotosGalerias/fotoGaleria1.webp"
import fotoGaleria2 from "../assets/fotosGalerias/fotoGaleria2.webp";
import fotoGaleria3 from "../assets/fotosGalerias/fotoGaleria3.webp";
import fotoGaleria4 from "../assets/fotosGalerias/fotoGaleria4.webp";
import fotoGaleria5 from "../assets/fotosGalerias/fotoGaleria5.webp";
import fotoGaleria6 from "../assets/fotosGalerias/fotoGaleria6.webp";
import fotoGaleria7 from "../assets/fotosGalerias/fotoGaleria7.webp";
import fotoGaleria8 from "../assets/fotosGalerias/fotoGaleria8.webp";
import fotoGaleria9 from "../assets/fotosGalerias/fotoGaleria9.webp";
import fotoGaleria10 from "../assets/fotosGalerias/fotoGaleria10.webp";
import fotoGaleria11 from "../assets/fotosGalerias/fotoGaleria11.webp";
import showBurlesque from "../assets/videos/videoShowBurlesque.webm";
import testimonios from "../assets/videos/videoTestimonios.webm";
import videoGaleria3 from "../assets/videos/videoLandingConceptDesktop.webm";
import videoGaleria4 from "../assets/videos/VALS.webm";

const Galeria = () => {
  const [playVideo, setPlayVideo] = useState(null);

  const images = [
    fotoGaleria1,
    fotoGaleria2,
    fotoGaleria3,
    fotoGaleria4,
    fotoGaleria5,
    fotoGaleria6,
    fotoGaleria7,
    fotoGaleria8,
    fotoGaleria9,
    fotoGaleria10
  ];

  const videoItems = [
    {
      url: videoGaleria3,
      thumbnail: fotoGaleria9,
      title: "Show falla técnica"
    },
    {
      url: showBurlesque,
      thumbnail: fotoGaleria4,
      title: "Show burlesque"
    },
    {
      url: videoGaleria4,
      thumbnail: fotoGaleria11,
      title: "Vals"
    },
  ];

  const handleVideoClick = (videoUrl) => {
    setPlayVideo(videoUrl);
  };

  return (
    <div className="gallery" id="galeria">
      <div className="carousel-images-wrapper carousel-margin">
        <Carousel autoPlay interval={3000} animation="slide" indicators={false} navButtonsAlwaysVisible={false}>
          {images.map((image, i) => (
            <Paper key={i} elevation={0} className="carousel-item" sx={{backgroundColor:"black"}}>
              <img src={image} alt={`Slide ${i}`} loading='lazy'/>
            </Paper>
          ))}
        </Carousel>
      </div>
      <div className="testimonios-wrapper carousel-margin">
        <div className="video-thumbnail-wrapper" onClick={() => handleVideoClick(testimonios)}>
          <img src={fotoGaleria2} alt="Video thumbnail" className="video-thumbnail" loading='lazy'/>
          <div className="video-icon-title">
            <PlayArrowIcon className="play-icon" />
            <span className="video-title">Testimonios</span>
          </div>
        </div>
      </div>
      <div className="carousel-video-wrapper carousel-margin">
        <Carousel autoPlay interval={3000} animation="slide" indicators={false} navButtonsAlwaysVisible={true}>
          {videoItems.map((video, i) => (
            <Paper key={i} elevation={0} className="carousel-item video-carousel-item">
              <div className="video-thumbnail-wrapper" onClick={() => handleVideoClick(video.url)}>
                <img src={video.thumbnail} alt={`Video thumbnail ${i}`} className="video-thumbnail" loading='lazy'/>
                <div className="video-icon-title">
                  <PlayArrowIcon className="play-icon" />
                  <span className="video-title">{video.title}</span>
                </div>
              </div>
            </Paper>
          ))}
        </Carousel>
      </div>
      {playVideo && (
        <div className="video-fullscreen-wrapper">
          <CloseIcon className="close-icon" onClick={() => setPlayVideo(null)} />
          <ReactPlayer
            url={playVideo}
            playing
            controls
            width="100%"
            height="100%"
          />
        </div>
      )}
    </div>
  );
};

export default Galeria;
