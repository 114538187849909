import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import ConcepLogo from "../assets/ConceptBlanco.webp";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useHamburger } from "../Contexts/BurgerContext";
import { HeaderContainer, HeaderLayout, MenuButton, Logo, CloseLogo } from "./styled/HeaderStyles";

const Header = () => {
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const { hamburgerMenuOn, toggleBurgerMenu } = useHamburger();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrolledDown = currentScrollPos > prevScrollPos;

      setVisible(!(isScrolledDown && currentScrollPos > 10));
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <>
      {!isDesktop && (
        <HeaderContainer className={visible ? "headerVisible" : "headerHidden"}>
          <HeaderLayout>
            <Logo src={ConcepLogo} alt="logo" />
            {hamburgerMenuOn ? (
              <CloseLogo as={IconButton} onClick={toggleBurgerMenu} aria-label="cerrar menu">
              <CloseIcon style={{ fontSize: "3.5rem", marginLeft: "1.5rem" }} />
            </CloseLogo>
            ) : (
              <MenuButton as={IconButton} onClick={toggleBurgerMenu} aria-label="abrir menu">
                <MenuIcon style={{ fontSize: "2em" }} />
              </MenuButton>
            )}
          </HeaderLayout>
        </HeaderContainer>
      )}
    </>
  );
};

export default Header;
