import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

export const RazonesWrapper = styled.div`
  background: linear-gradient(to right, #ff9240, #ff3e7a);
  color: black;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    padding-left: calc(6rem + 2rem);
    flex-direction: row;
  }
`;

export const Title = styled.h2`
  font-size: 3.1rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: black;

  @media (min-width: 1024px) {
    text-align: left;
    margin-bottom: 0;
    flex: 1;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-items: center;
  flex: 2;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ListItem = styled.li`
  font-size: 2rem;
  background: rgba(255, 0, 0, 0.12);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: black;
  width: 100%;
  max-width: 450px;
  font-weight: 600;
`;

export const CheckIcon = styled(FaCheck)`
  margin-right: 0.5rem;

  & path {
    color: black;
  }
`;
