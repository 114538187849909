import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TikTokIcon from '@mui/icons-material/MusicNote';

const iconData = [
  {
    id: 1,
    icon: <InstagramIcon style={{ fontSize: "2.4rem" }}/>,
    link: "https://www.instagram.com/conceptpro__/",
    label: "Instagram",
  },
  {
    id: 2,
    icon: <YouTubeIcon style={{ fontSize: "2.4rem" }} />,
    link: "https://www.youtube.com/@ConceptPro__",
    label: "YouTube",
  },
  {
    id: 3,
    icon: <TikTokIcon style={{ fontSize: "2.4rem"}} />,
    link: "https://www.tiktok.com/@concept.pro",
    label: "Tik Tok",
  },
];

export default iconData;
