import React, { useState } from 'react';
import { Typography, Button } from '@mui/material';
import styled from 'styled-components';
import '../styles/Nosotros.css';
import fotoLuchi from "../assets/fotoLuchi.webp";
import fotoMika from "../assets/fotoMika.webp";
import fotoLuchiMika from "../assets/fotoLuchiMika.webp";

const BubbleImage = styled.img`
  border-radius: 50%;
  width: ${props => props.size};
  height: ${props => props.size};
  object-fit: cover;
  // margin: 0.5rem;
  // padding: 10px;
`;

const Nosotros = () => {
  const [showMore, setShowMore] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useState(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="aboutUsContainer" id="nosotros">
      <div className="textSection">
        <Typography className="title" style={{ fontSize: '3.1rem' }} variant="h1" fontWeight={"bolder"} gutterBottom component={"h2"}>
          Sobre nosotros
        </Typography>
        <Typography className="text" style={{ fontSize: '1.7rem'}} variant="body1" gutterBottom>
          Somos un equipo de jóvenes especializados en shows para eventos.<br></br> Nuestra misión es crear espectáculos modernos y personalizados que sigan las tendencias actuales. <br></br>Descubri el arte de celebrar con nosotros: donde cada detalle cobra vida en una experiencia única!
        </Typography>
        <div className={`additionalText ${showMore ? 'open' : ''}`}>
          <Typography className="text" style={{ fontSize: '1.7rem' }} variant="body2" gutterBottom>
            Comprendiendo que la diversión y el baile van de la mano, nos ocupamos desde la idea inicial hasta el aplauso final, transformando tus eventos en un escenario de emociones y dejando una huella imborrable en cada espectador.
          </Typography>
        </div>
        {isDesktop ? (
          <Typography className="text" style={{ fontSize: '1.7rem' }} variant="body2" gutterBottom>
            Comprendiendo que la diversión y el baile van de la mano, nos ocupamos desde la idea inicial hasta el aplauso final, transformando tus eventos en un escenario de emociones y dejando una huella imborrable en cada espectador.
          </Typography>
        ) : (
          <Button variant="contained" onClick={toggleShowMore} size='large' style={{
            marginTop: '1rem',
            borderRadius: "14px",
            background: 'linear-gradient(to right, #ff9240, #ff3e7a)', 
            color: 'white',
            fontWeight: "bolder",
            textTransform: "none",
            fontSize: "1.2rem"
          }}>
            {showMore ? 'Ocultar' : 'Descubrinos'}
          </Button>
        )}
      </div>
      <div className='imagesSection'>
        <BubbleImage src={fotoLuchiMika} alt='foto bailarinas' className='fotoLuchiMika' loading='lazy'  />
        <BubbleImage src={fotoMika} alt='foto bailarina' className='fotoMika' loading='lazy' />
        <BubbleImage src={fotoLuchi} alt='foto bailarina' className='fotoLuchi'  loading='lazy'/>
      </div>
    </div>
  );
};

export default Nosotros;