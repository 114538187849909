import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContainerLanding from "./components/ContainerLanding";
import { HamburgerProvider } from "./Contexts/BurgerContext";
import './index.css';
import './styles/fonts.css';

function App() {
  return (
    <Router>
      <HamburgerProvider>
        <Routes>
          <Route path="/*" element={<ContainerLanding />} />
        </Routes>
      </HamburgerProvider>
    </Router>
  );
}

export default App;
