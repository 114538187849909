import "../styles/Sidebar.css";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import * as React from "react";
import { useHamburger } from "../Contexts/BurgerContext";
import CloseIcon from "@mui/icons-material/Close";
import ConcepLogo from "../assets/ConceptBlanco.webp";

const Sidebar = () => {
  const { hamburgerMenuOn, toggleBurgerMenu } = useHamburger();

  return (
    <>
      <div className="sidebar">
        <div className="sidebarLayout">
          <img src={ConcepLogo} alt="logo" className="logoDesktop" />
          {hamburgerMenuOn ? (
            <IconButton className="closeLogo" onClick={toggleBurgerMenu} aria-label="cerrar menu">
              <CloseIcon style={{ fontSize: "3.5rem", marginBottom: "1rem" }} />
            </IconButton>
          ) : (
            <IconButton className="menuButton" onClick={toggleBurgerMenu} aria-label="abrir menu">
              <MenuIcon style={{ fontSize: "2em" }} />
            </IconButton>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
