import React, { createContext, useContext, useState } from "react";

const HamburgerContext = createContext();

export const HamburgerProvider = ({ children }) => {
  const [hamburgerMenuOn, setHamburgerMenuOn] = useState(null);

  const toggleBurgerMenu = () => {
    if (hamburgerMenuOn === null) {
      setHamburgerMenuOn(true);
    } else {
      setHamburgerMenuOn(!hamburgerMenuOn);
    }
  };

  return (
    <HamburgerContext.Provider
      value={{
        hamburgerMenuOn,
        toggleBurgerMenu,
      }}
    >
      {children}
    </HamburgerContext.Provider>
  );
};

export const useHamburger = () => {
  const context = useContext(HamburgerContext);
  if (!context) {
    throw new Error("useHamburger must be used within a HamburgerProvider");
  }
  return context;
};
