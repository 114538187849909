import * as React from "react";
import '../index.css';
import '../styles/fonts.css'; 
import Landing from "./Landing";
import Sidebar from "./Sidebar";
import "../styles/ContainerLanding.css";
import Header from "./Header";
import Nosotros from "./Nosotros";
import { useHamburger } from "../Contexts/BurgerContext";
import Menu from "./Menu";
import Servicios from "./Servicios";
import Galeria from './Galeria';
import Contacto from "./Contacto";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Razones from "./Razones";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontFamily: "'Martel Sans', sans-serif",
    },
  },
});

const ContainerLanding = () => {
  const { hamburgerMenuOn } = useHamburger();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const section = location.hash.replace("#", "");
    if (section) {
      document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
    }

    // Resetear la URL para que no mantenga el hash al recargar la página
    window.addEventListener('beforeunload', () => {
      navigate("/", { replace: true });
    });

    return () => {
      window.removeEventListener('beforeunload', () => {
        navigate("/", { replace: true });
      });
    };
  }, [location, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <div className="containerLanding" id="containerLanding">
        <Header />
        <Menu hamburgerMenuOn={hamburgerMenuOn} />
        <Sidebar />
        <Landing />
        <Nosotros />
        <Servicios />
        <Razones />
        <Galeria />
        <Contacto />
      </div>
    </ThemeProvider>
  );
};

export default ContainerLanding;
